/* App.css */

/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Header styles */
h1 {
  color: #333;
  font-size: 36px;
  margin-top: 20px;
  text-align: center;
}

/* Subtitle styles */
p {
  color: #777;
  font-size: 18px;
  text-align: center;
}

/* Main features styles */
h2 {
  color: #333;
  font-size: 24px;
  margin-top: 40px;
}

ol {
}

li {
  color: #555;
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
}

/* Add more styling as needed */
